import React, {useRef, useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {PortfolioHeader, TabSection} from "../components/PortfolioPage/styles";
import PortfolioImage from "../images/portfolio_header_bg.svg"
import {Tab, Tabs} from "../components/Tabs";
import {PortfolioTabs} from "../components/Tabs/PortfolioTabs";
import {SectionTitle} from "../components/SectionTitle/styles";
import {colors} from "../const/colors";
import {IoCheckmark} from "react-icons/io5";
import queryString from "query-string";
import localData from "../localization/privacy-policy.json";

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

const IndexPage = ({data, location, pageContext}) => {
    const search = queryString.parse(location.search);
    const { lang } = pageContext
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)
    return <Layout location={location} lang={lang} flags={flags}>
    <SEO
        title={ meta?.metaTitle || metaStatic.metaTitle}
        description={ meta?.metaDescription || metaStatic.metaDescription}
        keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
    />
      <PortfolioHeader title="Privacy Policy" background={PortfolioImage} />
      {/*<div style={{position:"relative"}}  />*/}
      <TabSection style={{paddingBottom:"0"}}>
          <PortfolioTabs searchTerm={search}>
            <Tab label="general" title="General">
                <div className="inside-wrapper" style={{background: "white"}}>
                  <div style={{margin:"50px auto 0px auto"}}>
                      <SectionTitle align="center">General</SectionTitle>
                      <p style={{textAlign:"center"}}>Green Flamingo SL operates the www.greenflamingobcn.com website (hereinafter referred to as the "service").
                          This privacy policy describes how Green Flamingo SL based in Carrer de Mallorca, 536 08026 Barcelona, Spain collects,
                          uses and shares Service-related information about you.</p>
                      <p style={{textAlign:"center", color:colors.greenColor, fontWeight:"bold"}}>We use your data to provide and improve the service.<br />
                          By using the service, you agree to the collection and use of information in accordance with this policy. </p>
                  </div>
                </div>
                <div className="inside-wrapper" style={{background:colors.lightBg}}>
                    <div>
                        <SectionTitle align="center">Contact</SectionTitle>
                        <p style={{textAlign:"center"}}>If you have any questions about this Privacy Policy, please contact us by email: <br/>
                            <a style={{color:colors.greenColor, textDecoration:"none", fontWeight:"bold"}} href="">info@greenflamingobcn.com</a>
                        </p>
                    </div>
                </div>
            </Tab>
            <Tab label="information-collection-and-use" title="Information colleciton and use">
               <div className="inside-wrapper" style={{textAlign:"center", background:"white"}}>
                    <div style={{margin:"50px auto 0px auto"}}>
                        <SectionTitle align="center">Information collection and use</SectionTitle>
                        <p style={{maxWidth:"900px", margin:"0px auto 1.45rem auto"}}>We collect several different types of information for various purposes to provide and improve our service to you.</p>
                        <h4 style={{textAlign:"center", color:colors.grayColor}}>Types of Data Collected</h4>
                        <span style={{display:"inline-block", color:colors.greenColor, margin:"0px auto 25px auto", fontWeight:"bold"}}>Personal Data</span>
                        <p style={{maxWidth:"930px", margin:"0px auto 1.45rem auto"}}>While using our service, we may ask you to provide us with certain personally identifiable information that can
                            be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
                        <ul className="with-checkmarks">
                            <li><IoCheckmark color={colors.greenColor} /><strong>Email address</strong></li>
                            <li><IoCheckmark color={colors.greenColor} /><strong>Phone number</strong> </li>
                            <li><IoCheckmark color={colors.greenColor} /><strong>First name and last name</strong></li>
                            <li><IoCheckmark color={colors.greenColor} /><strong>Cookies and Usage Data</strong></li>
                            <li><IoCheckmark color={colors.greenColor} /><strong>Address ( City, State, Province, ZIP/Postal code)</strong></li>
                        </ul>
                        <span style={{display:"inline-block", color:colors.greenColor, margin:"0px auto 25px auto", fontWeight:"bold"}}>Usage Data</span>
                        <p style={{maxWidth:"900px", margin:"0px auto 1.45rem auto"}}>We may also collect information on how the service is accessed and used ("Usage Data"). This Usage Data
                            may include information such as your computer's Internet Protocol address (e.g. IP address),
                            browser type, browser version, the pages of our Service that you visit, the time and date of your visit,
                            the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                        <span style={{display:"inline-block", color:colors.greenColor, margin:"0px auto 25px auto", fontWeight:"bold"}}>Tracking & Cookies Data</span>
                        <p style={{maxWidth:"900px", margin:"0px auto 1.45rem auto"}}>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.
                            Cookies are files with small amount of data, which may include an anonymous unique identifier.
                            Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used
                            are beacons, tags, and scripts to collect and track information and to improve and analyze our service.
                            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you
                            do not accept cookies, you may not be able to use some portions of our service. You can learn more how to
                            manage cookies in the Browser Cookies Guide.</p>
                        <h4 style={{textAlign:"center", color:colors.grayColor}}>Use of Data</h4>
                        <p>Green Flamingo SL uses the collected data for various purposes:</p>
                        <ul className="with-checkmarks full-width">
                            <li><IoCheckmark color={colors.greenColor} /><strong>To comply with a legal obligation</strong></li>
                            <li><IoCheckmark color={colors.greenColor} /><strong>To protect and defend the rights or property of Green Flamingo SL</strong> </li>
                            <li><IoCheckmark color={colors.greenColor} /><strong>To prevent or investigate possible wrongdoing in connection with the service</strong></li>
                            <li><IoCheckmark color={colors.greenColor} /><strong>To protect the personal safety of users of the service or the public</strong></li>
                            <li><IoCheckmark color={colors.greenColor} /><strong>To protect against legal liability</strong></li>
                        </ul>
                        <h4 style={{textAlign:"center", color:colors.grayColor}}>Security of Data</h4>
                        <p style={{maxWidth:"900px", margin:"0px auto 1.45rem auto"}}>The security of your data is important to us, but remember that no method of transmission over the Internet,
                            or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect
                            your Personal Data, we cannot guarantee its absolute security.</p>
                        <h4 style={{textAlign:"center", color:colors.grayColor}}>Service Providers</h4>
                        <p style={{maxWidth:"900px", margin:"0px auto 1.45rem auto"}}>We may employ third party companies and individuals to facilitate our service ("Service Providers"),
                            to provide the service on our behalf, to perform service-related services or to assist us in analyzing how our s ervice is used.
                            <br/>
                            <br/>
                            These third parties have access to your Personal Data only to perform these tasks on our behalf and
                            are obligated not to disclose or use it for any other purpose.
                        </p>
                    </div>
               </div>
                <div className="inside-wrapper" style={{background:colors.lightBg}}>
                    <div>
                        <SectionTitle align="center">Contact</SectionTitle>
                        <p style={{textAlign:"center"}}>If you have any questions about this Privacy Policy, please contact us by email: <br/>
                            <a style={{color:colors.greenColor, textDecoration:"none", fontWeight:"bold"}} href="">info@greenflamingobcn.com</a>
                        </p>
                    </div>
                </div>
            </Tab>
            <Tab label="changes-to-this-privacy-policy" title="Changes to this privacy policy">
                <div className="inside-wrapper" style={{textAlign:"center", background:"white"}}>
                    <div style={{margin:"50px auto 0px auto"}}>
                        <SectionTitle align="center">Changes to this privacy policy</SectionTitle>
                        <p style={{maxWidth:"900px", margin:"50px auto 1.45rem auto"}}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
                            Privacy Policy on this page.
                            <br/><br/><br/>
                            We will let you know via email and/or a prominent notice on our service, prior to the change becoming effective
                            and update the "effective date" at the top of this Privacy Policy.
                            <br/><br/><br/>
                            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
                            are effective when they are posted on this page.
                        </p>
                    </div>
                </div>
                <div className="inside-wrapper" style={{background:colors.lightBg}}>
                    <div>
                        <SectionTitle align="center">Contact</SectionTitle>
                        <p style={{textAlign:"center"}}>If you have any questions about this Privacy Policy, please contact us by email: <br/>
                            <a style={{color:colors.greenColor, textDecoration:"none", fontWeight:"bold"}} href="">info@greenflamingobcn.com</a>
                        </p>
                    </div>
                </div>
            </Tab>
          </PortfolioTabs>
      </TabSection>
  </Layout>
}

export default IndexPage
